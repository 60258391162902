<!-- 短信日志 -->
<template>
  <div id="sms-log">
    <div class="title">
      <img src="../../assets/images/LTicon.png" alt="" />
      <span>短信日志</span>
    </div>
    <div class="content">
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="formData">
          <el-form-item label="变配电站">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="开始时间">
            <el-date-picker
              type="date"
              v-model="formData.date1"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker
              type="date"
              v-model="formData.date1"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="发送结果">
            <el-select v-model="formData.region" placeholder="">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search">查询</el-button>
            <el-button type="primary" icon="el-icon-download">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table class="table" :data="tableData">
        <el-table-column align="center" label="序号" prop="id" width="150">
        </el-table-column>
        <el-table-column align="center" label="变配电站名称" prop="name">
        </el-table-column>
        <el-table-column align="center" label="发送时间" prop="one" width="170">
        </el-table-column>
        <el-table-column align="center" label="手机号" prop="two">
        </el-table-column>
        <el-table-column align="center" label="发送结果" prop="three">
        </el-table-column>
        <el-table-column align="center" label="发送内容" prop="four">
        </el-table-column>
        <el-table-column align="center" label="回复信息" prop="five">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <button class="home-page">首页</button>
        <el-pagination
          :current-page="requestParam.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          layout="prev, pager, next, sizes,slot"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          @size-change="changeSize"
          @current-change="changeCurrent"
        >
        </el-pagination>
        <button class="tail-page">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input
            size="mini"
            class="pagination-input"
          ></el-input>
          <span class="text">页</span>
          <button class="confirm">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { eventApi } from "@/api/event.js";
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
      },
      tableData: [
        {
          id: 1,
          name: "变配电站名称",
          one: "2023-10-23 16:02:08",
          two: "19563248745",
          three: "成功",
          four: "内容",
          five: "内容",
        },
        {
          id: 2,
          name: "变配电站名称",
          one: "2023-10-23 16:02:08",
          two: "19563248745",
          three: "成功",
          four: "内容",
          five: "内容",
        },
        {
          id: 3,
          name: "变配电站名称",
          one: "2023-10-23 16:02:08",
          two: "19563248745",
          three: "成功",
          four: "内容",
          five: "内容",
        },
      ],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
    };
  },

  mounted() {
    // this.queryPlatform();
  },

  methods: {
    changeSize(pageSize) {
      this.requestParam.pageNum = pageSize;
      this.queryPlatform();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryPlatform();
    },
    queryPlatform() {
      eventApi.platform(this.requestParam).then((res) => {
        this.tableData = res.data;
        this.total = res.total;
      });
    },
  },
};
</script>
<style scoped>
#sms-log {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  padding: 20px 25px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}
/* 查询表单 */
.query-form {
  min-height: 62px;
  background-color: rgba(6, 29, 74, 0.79);
  display: flex;
  align-items: center;
  padding: 2px;
  margin-bottom: 20px;
}
.form :deep() .el-input {
  width: 176px;
}
.form :deep() .el-icon-date {
  color: #aed6ff;
}
.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}
.form :deep() .el-form-item {
  margin: 10px 16px 10px 0;
}
/* input */
.form :deep() .el-input .el-input__inner,
.form :deep() .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
}
/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}

/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination :deep() .el-pagination {
  padding-left: 0;
}
.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}
.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}
.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination >>> .btn-prev,
.el-pagination >>> .btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.el-pagination >>> .el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}
.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  color: #92b5da;
  margin: 0 10px;
}
.pagination-input >>> .el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
}
.pagination :deep().el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}
.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}
.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}
</style>
